.headingRow {
  margin-top: 0.5em;
}

.actionRow {
  margin-top: 0.5em;
}

.searchHelpIcon {
  margin-left: 0.7em;
}

.paginationButtons {
  margin-left: 0.2rem;
}
